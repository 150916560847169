.customVesselDropDown {
    display: flex;
    background: #2860B4;
    border-radius: 50px;
    color: #ffffff;
    margin-right: 34px;
}

.selectedVesselName {
    color: #ffffff;
    font-size: 14px !important;
    padding-left: 12px;
}
.ouDropDown {
    display: flex;
    background: #2860B4;
    border-radius: 50px;
    color: #ffffff;
    margin-right: 15px;
    width: 190px;
    position: relative;
}
.ouDropDown svg {
    position: absolute;
    right: 10px;
}

.selectedOuName {
    color: #ffffff;
    font-size: 14px !important;
    padding-left: 12px;
    font-weight: 500 !important;
}

.childHierarch:hover {
    /* padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 500px;
    width: 100%; */
}

.childHierarch {
    padding-left: 1px !important;
    padding-right: 5px !important;
    color: #000000;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
}

.childHierarch > span {
    text-overflow: ellipsis;
    overflow: hidden;
}

.childHierarchy {
    height: 400px;
    /* width: 300px; */
    /* overflow: auto; */
    margin-top: 22px;
    margin-left: 150px;
}

.childern {
    padding-left: 10px;
    padding-right: 5px;
}
#menu-ou {
    font-weight: 600 !important;
    font-size: 10px !important;
    line-height: 12px !important;
    letter-spacing: 0.05em !important;
    text-transform: capitalize !important;
    margin: 0px !important;
}
#menu-ou .MuiPaper-root{
    width: 190px !important;
    max-height: 300px;
    /* min-height: 200px; */
    background-color: #ffffff;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    /* box-shadow: none; */
}

.OuMenuChild {
    font-weight: 600 !important;
    line-height: 12px !important;
    letter-spacing: 0.05em !important;
    text-transform: capitalize !important;
    margin-left: 10px !important;
    color: #ffffff !important;
    padding: 0px !important;
    font-size: 1em !important;
    padding: 3px !important;
} 

.OuMenuChild .MuiListItemText-root .MuiTypography-root {
    font-size: 0.9em !important;
}

#single_vessel_selector {
    color: #ffffff;
    font-size: 14px;
    padding-left: 10px;
}


.tf-single-vessel .MuiInput-root:before, .MuiInput-root:hover {
    border-bottom: 0px !important;
}

.tf-single-vessel .MuiAutocomplete-endAdornment .MuiButtonBase-root{
    color: #ffffff;
}
.tf-single-vessel .MuiChip-root, .MuiChip-filled {
    color: #ffffff;
    background: #2860B4;
    height: 18px;
    margin: -5px;
}

.tf-single-vessel .MuiChip-root .MuiChip-deleteIcon{
    font-size: 15px;
}


.tf-single-vessel .MuiChip-root .MuiAutocomplete-tag {
    color: #ffffff;
}

.tf-single-vessel .MuiAutocomplete-tag {
    color: #ffffff;
}

#menu-vessels {
    max-height: 400px;
}

#menu-vessels .MuiPaper-root {
    background: #ffffff;
    border-radius: 15px;
    width: 190px;
    max-height: 300px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    /* box-shadow: none; */
}

#menu-vessels-new-summary .MuiPaper-root {
    background: #ffffff;
    border-radius: 15px;
    width: 130px;
    max-height: 300px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    /* box-shadow: none; */
}
#menu-vessels-new-summary-period.min-width .MuiPaper-root {
    width: 130px;
}

#menu-vessels-new-summary-period .MuiPaper-root {
    background: #ffffff;
    border-radius: 15px;
    width: 185px;
    max-height: none;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    /* box-shadow: none; */
}

.vessel_search_item form .MuiFormControl-root .MuiOutlinedInput-root{
    height: 20px !important;
}

.vessel_search_item form .MuiFormControl-root .MuiOutlinedInput-root .MuiInputAdornment svg{
    font-size: 12px;
}

.vessel_name_item {
    color: "#ffffff";
    padding: 5px 5px 5px 15px !important;
}
.vessel_name .MuiTypography-root {
    font-size: 12px;
    /* color: #ffffff; */
}

.vessel_name > span {
    text-overflow: ellipsis;
    overflow: hidden;
}

.vessel_name_item svg {
    font-size: 12px;
}

.searchtext {
    width: 100%;
}

.vesselDropdown {
    color: #ffffff !important;
    font-size: 14px !important;
    padding-left: 12px !important;
    display: flex !important;
    background: transparent !important;
    border-radius: 50px !important;
    margin-right: 24px !important;
    height: 26px !important;
    width: 190px !important;
    text-transform: capitalize !important;;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    letter-spacing: 0.05em !important;
    padding: 0px !important;
    align-items: center;
}

.layout_NewSummary .new-summary-vesselDropdown {
    color: #ffffff ;
    font-size: 14px ;
    padding-left: 12px ;
    display: flex ;
    background: transparent ;
    border-radius: 50px ;
    margin-right: 24px ;
    height: 26px ;
    width: 130px ;
    text-transform: capitalize ;;
    font-style: normal ;
    font-weight: 500 ;
    line-height: 16px ;
    letter-spacing: 0.05em ;
    padding: 0px ;
    align-items: center;
}

.vesselDropdownInput {
    color: black !important;
    font-size: 14px !important;
    padding-left: 12px !important;
    display: flex !important;
    background: #ffffff;
    border-radius: 4px !important;
    margin-right: 34px !important;
    height: 32px ;
    width: 2270px !important;
    text-transform: capitalize !important;;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    letter-spacing: 0.05em !important;
    padding: 0px !important;
}

.vesselDropdown .vesselDropdownInput {
    background: #fff;
}

.vesselDropdown .vesselDropdownInput:hover {
    background: #fff;
}

.background-gray .new-summary-vesselDropdown .MuiButton-root {
    background: #fff;
    box-shadow: none;
    border: 1px solid #EFEFEF;
    padding-right: 15px !important;
}

.layout_NewSummary .MuiButton-containedPrimary {
    margin: 15px 0px;
}

.padding-right-15 {
    padding-right: 15px;
}

.vesselDropdown-button{
    margin-right: 0px !important;
}
.vesselDropdown-button span {
    position: absolute;
    left: 10px;
}

.vesselDropdown-button svg {
    position: absolute;
    right: 10px;
}

.on_dropdown_open {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}

.textWrap {
    width: 154px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 17px;
    text-align: left;
    text-transform: none;
    font-family: 'Inter';
    font-size: 14Px;
    font-weight: 600;
    font-family: inter;
    line-height: 17px;
    height: 18px;
    color: #45464E;
}

.vesselDropdownInput .textWrapSpan{
    position: absolute;
    left: 100px;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: inter;
    font-weight: 600;
}

.vessel_name_item .textWrapInner {
    width: 120px ;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 17px;
    text-align: left;
}

.vessel_name_item .textWrapInnerSL {
    width: 140px ;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 12px;
    text-align: left;
    display: block;
}

 .MuiTooltip-popper.MuiTooltip-popperInteractive .MuiTooltip-tooltipArrow.topZero {
    margin-top: 0px;
}

.layout_NewSummary{
    display: inherit;
    align-items: center;
}

.layout_NewSummary .globalFilterMarginRight {
    margin-right: 24px !important;
}

.mainGrid_NewSummary{
    margin-right: 15px;
}

.vesselDropdownInput .keyDownIcon_NewSummary{
    cursor: pointer;
     transform: rotate(-180deg);
    margin-right: 15px;
}

.vesselDropdownInput .wan-keyDownIcon_NewSummary, .vesselDropdownInput .period-keyDownIcon_NewSummary {
    margin-right: 0px;
}

.page-drop {
    position: absolute;
    right: 0;
    padding-right: 10px;
    top: 8px;
    height: 10px;
    width: 10px;
}

.loader_NewSummary{
    position: absolute;
    top: 63px;
    right: 36px;
}

.checkedIcon_NewSummary{
    color: #ffffff;
    background: #2860B4;
}

.unCheckedIcon_NewSummary{
    color: #ffffff;
    border: 1px solid #2860B4;
}

.checkedSingleVessel{
    color:#2860B4;
    background-color: #ffffff;
}

.uncheckedSingleVessel{
    color:#ffffff;
    border:1px solid solid #2860B4;
    border-radius: 50%;
}

.span_NewSummary {
    font-size: 15px;
}

.serachBar .searchIcon_NewSummary{
    /* style={{ position: "absolute", right: "12px", fontSize: "12px" }} */
    position: absolute;
    right: 12px;
    font-size: 12px;
}

.serachBar .checkLine_NewSummary{
    position: absolute;
    right: 25px;
    font-size: 24px;
    font-weight: 600;
}

.applyButtonWrapper_ns{
    display: flex;
    justify-content: space-between;
    padding: 15px 10px 10px 15px;

}

.applyButtonWrapper_ns .applyButtonCustomDate_ns{
    color:white;
    width: 39px;
    height: 25px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;     
    text-transform: none; 
    border-radius: 4px;
    background-color: #1B51A7;  

}

.applyButtonWrapper_ns .applyButtonCustomDate_ns:hover{
    background-color: #1B51A7;  
}

.applyButtonWrapper_ns .cancelButtonCustomDate_ns{
    color:#6E7079;
    width: 39px;
    height: 25px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;     
    text-transform: none; 
    border-radius: 4px;
    background-color: #ffffff;  

}

/* width */
.menuScroll ::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }
  
  /* Track */
  .menuScroll ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(255, 254, 254);
    border-radius: 18px !important;
    background: rgb(255, 255, 255);
  }
  
  /* Handle */
  .menuScroll ::-webkit-scrollbar-thumb {
    background:#D9D9D9 !important;
    border-radius: 0px !important;
  }
  
  /* Handle on hover */
  .menuScroll  ::-webkit-scrollbar-thumb:hover {
    background:#ffffff !important;
  }

  .span_TimePeriod .newSummaryTimePeriod{
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
  }

  .ns_gray{
    color:#8B8D97;
  }

  .ns_white{
    color:#ffffff;
    padding-left: 16px;
  }

  .ns_time_period_container{
    display: flex;
    padding-top: 8px;
  }

  .layout_NewSummary .ns_common_filters_typo{
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    padding-right: 16px;
    color:#8B8D97;
    height: 18px;
  }

.mainGrid_NewSummary #enable-cursor {
    cursor: pointer;
}

.mainGrid_NewSummary #disable-cursor {
    cursor: auto;
}

.vesselDropdown #enable-cursor {
    cursor: pointer;
    box-shadow: none;
    border: 1px solid #EFEFEF;
}

.margin-left-auto {
    margin-left: auto;
}

.search-dropdown-limit-div .margin-left-auto {
    margin-left: auto;
}

.vesselDropdown #disable-cursor {
    cursor: auto;
    background: #c8c8c8;
    opacity: 0.6;
}

.vesselDropdown .button-shadow {
    box-shadow: none;
    border: 1px solid #EFEFEF;
}

.padding-r-15 {
    padding-right: 15px;
}

#menu-vessels-new-summary-period .custom-date-selection-div .MuiFormControl-root{
    width: 155px;
}

.periodSelection {
    display: table;
    position: fixed;
    right: 44px;
    cursor: pointer;
    color: #2860B4;
}

.periodInnerContainer {
    display: table-cell;
    vertical-align: middle;
}

.plusMinusIc {
    color: black;
    font-size: 15px;
    margin-right: 10px;
}

.new-period-div-container {
    display: flex;
    align-items: center;
}

.new-period-div-container>span:nth-child(1) {
    position: fixed;
    right: 186px;
    width: 115px;
}

.new-period-div-container .newSummaryTimePeriod {
    font-size: 10px;
    color: #45464e;
    font-family: "Inter";
    font-weight: 600;
    font-size: 11px;
    font-family: monospace;
    font-weight: 500;
    color: rgba(110, 112, 121, 1);
}

.new-period-div-container .textWrap-new-period {
    left: auto;
    right: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    width: unset !important;
    margin-right: unset;
}

#menu-vessels-new-summary-period .vessel_name_item {
    width: 100%;
}

.periodSelection .newPeriod-globalFilterMarginRight {
    margin-right: 0px !important;
    width: 251px;
}

.newPeriod-globalFilterMarginRight .vesselDropdownInput {
    height: 32px;
    width: 140px !important;
    position: fixed;
    right: 46px;
}

.commonFilters .sdwan-vesselDropdown {
    width: 255px !important;
}

.sdwan-vesselDropdown .textWrap {
    width: 230px !important;
}

#sdwan-menu-vessels {
    max-height: 400px;
}

#sdwan-menu-vessels .MuiPaper-root {
    width: unset;
    background: #ffffff;
    border-radius: 15px;
    max-height: 300px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}